import { INavData } from "@coreui/angular";

const dashboardNav: INavData[] = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: "icon-speedometer"
  }
];

const GamesNav: INavData[] = [
  {
    name: "Limits",
    url: "/games/limits",
    icon: "icon-frame"
  },
  {
    name: "Speed",
    url: "/games/speed",
    icon: "cil-people"
  },
  {
    name: "Timers",
    url: "/games/timers",
    icon: "cil-av-timer"
  },
  {
    name: "Timers Per Speed",
    url: "/games/timers-per-speed",
    icon: "cil-av-timer"
  },
  {
    name: "League",
    url: "/games/league",
    icon: "cil-people"
  },
  {
    name: "Game Stats",
    url: "/games/gamestate",
    icon: "cil-location-pin"
  }
];
const EducatorNav: INavData[] = [
  {
    name: "school",
    url: "/educator/school",
    icon: "icon-frame"
  },
  {
    name: "language",
    url: "/educator/language",
    icon: "cil-people"
  },
  {
    name: "grads",
    url: "/educator/grads",
    icon: "cil-map"
  },
  {
    name: "administration",
    url: "/educator/administration",
    icon: "icon-frame"
  },
  {
    name: "campus",
    url: "/educator/campus",
    icon: "cil-people"
  },
  {
    name: "classrooms",
    url: "/educator/classrooms",
    icon: "cil-map"
  },
  {
    name: "students",
    url: "/educator/students",
    icon: "cil-people"
  },
  {
    name: "teachers",
    url: "/educator/teachers",
    icon: "cil-map"
  }
];

const MembershipNav: INavData[] = [
  {
    name: "Attributes",
    url: "/membership/attributes",
    icon: "icon-frame"
  },
  {
    name: "Plans",
    url: "/membership/plans",
    icon: "cil-people"
  },
  {
    name: "Offers",
    url: "/membership/offers",
    icon: "cil-map"
  },
  {
    name: "Account",
    url: "/membership/account",
    icon: "cil-people"
  }
];
const ProgressNav: INavData[] = [
  {
    name: "My Progress",
    url: "/progress/myprogress",
    icon: "icon-frame"
  },
  {
    name: "Level",
    url: "/progress/level",
    icon: "icon-frame"
  },
  {
    name: "My Ranking",
    url: "/progress/myranking",
    icon: "cil-people"
  },
  {
    name: "Achievement Rules",
    url: "/progress/achievement-rules",
    icon: "cil-people"
  },
  {
    name: "Achievement Report",
    url: "/progress/achievement-report",
    icon: "cil-people"
  }
];

const ArrayNav: INavData[] = [
  {
    name: "Global Setting",
    url: "/arrays/global-setting",
    icon: "cil-settings",
    children: [
      { name: "Definitions", url: "/arrays/global-setting/setting", icon: "cil-settings" },
      { name: "Types", url: "/arrays/global-setting/types", icon: "cil-settings" },
      { name: "Schema", url: "/arrays/global-setting/numbering-scheme", icon: "cil-settings" }
    ]
  },
  {
    name: "Arrays",
    url: "/arrays/data",
    icon: "icon-frame"
  },
  {
    name: "Organization",
    url: "/arrays/organization",
    icon: "cil-people"
  },
  {
    name: "Geography",
    url: "/arrays/geography",
    icon: "cil-map"
  },
  {
    name: "Partner",
    url: "/arrays/partner",
    icon: "cil-people"
  },
  {
    name: "Localization",
    url: "/arrays/localization",
    icon: "cil-location-pin"
  },
  {
    name: "Product Category",
    url: "/arrays/category",
    icon: "cil-people"
  },
  {
    name: "Character",
    url: "/arrays/character",
    icon: "cil-location-pin"
  },
  {
    name: "Series",
    url: "/arrays/series",
    icon: "cil-map"
  },
  {
    name: "Content",
    url: "/arrays/content",
    icon: "icon-book-open"
  },
  {
    name: "Quiz",
    url: "/arrays/quiz",
    icon: "cil-comment-bubble-question"
  },
  {
    name: "Store",
    url: "/arrays/store",
    icon: "cil-people"
  },
  {
    name: "Stock",
    url: "/arrays/stock",
    icon: "cil-people"
  },
  {
    name: "Distribution",
    url: "/arrays/distribution",
    icon: "cil-people"
  },
  {
    name: "Transaction",
    url: "/arrays/transactions",
    icon: "cil-people"
  },
  {
    name: "Attribute Group",
    url: "/arrays/attribute-group",
    icon: "cil-people"
  }
];

const assetsNav: INavData[] = [
  { name: "Asset Type", url: "/assets-repo/asset-type", icon: "cil-people" },
  { name: "Content Images", url: "/assets-repo/content-images", icon: "cil-people" },
  { name: "Marketing Images", url: "/assets-repo/marketing-images", icon: "cil-people" },
  { name: "UI Images", url: "/assets-repo/ui-images", icon: "cil-people" },
  { name: "Content Files", url: "/assets-repo/content-files", icon: "cil-people" }
];

export const navItems: (path?: string) => INavData[] = path => {
  if (!path) return dashboardNav;
  path = path.split("/")[1] || "";

  switch (path) {
    case "arrays":
      return [...dashboardNav, ...ArrayNav];
    case "assets-repo":
      return [...dashboardNav, ...assetsNav];
    case "games":
      return [...dashboardNav, ...GamesNav];
    case "membership":
      return [...dashboardNav, ...MembershipNav];
    case "progress":
      return [...dashboardNav, ...ProgressNav];
    case "educator":
      return [...dashboardNav, ...EducatorNav];
    default:
      return dashboardNav;
  }
};
