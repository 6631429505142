import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DraggableModalComponent } from "./draggable-modal/draggable-modal.component";
import { FormsModule } from "@angular/forms";
import { SearchPopComponent } from "./search-pop/search-pop.component";
import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";
import { FilterComponent } from "./filter/filter.component";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { CcxLoaderComponent } from "./ccx-loader/ccx-loader.component";
import { OptionSelectorInputComponent } from "./option-selector-input/option-selector-input.component";
import { MessageModalComponent } from "./message-modal/message-modal.component";

@NgModule({
  declarations: [
    DraggableModalComponent,
    SearchPopComponent,
    FilterComponent,
    CcxLoaderComponent,
    OptionSelectorInputComponent,
    MessageModalComponent
  ],
  imports: [CommonModule, ModalModule, TabsModule, FormsModule, AngularMultiSelectModule],
  exports: [
    DraggableModalComponent,
    SearchPopComponent,
    FilterComponent,
    CcxLoaderComponent,
    OptionSelectorInputComponent,
    CommonModule,
    FormsModule,
    ModalModule,
    MessageModalComponent
  ]
})
export class ReusablesModule {}
