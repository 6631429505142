import { createAction, props } from "@ngrx/store";
import { ArrayInterface } from "../../../models/arrayInterface";

export enum CcxArrayActionTypes {
  Add = "[ccxArray] Add CcxArray",
  Remove = "[ccxArray] Remove CcxArray",
  retrieve = "[ccxArray] Load CcxArray"
}

export const AddCcxArray = createAction(CcxArrayActionTypes.Add);
export const RetriveCcxArray = createAction(CcxArrayActionTypes.retrieve, props<{ ccxArray: ArrayInterface[] }>());
