import { createReducer, on } from "@ngrx/store";
import { ArrayInterface } from "../../../models/arrayInterface";

import { RetriveCcxArray } from "../Actions/ccxArray.action";

const initialState: ArrayInterface[] = [];

const _CcxArrayReducer = createReducer(
  initialState,
  on(RetriveCcxArray, (state, { ccxArray }) => {
    return [...ccxArray];
  })
);

export function CcxArrayReducer(state: any, actions: any) {
  return _CcxArrayReducer(state, actions);
}
