import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { allArray } from "./Store/selector/array.selector";
import { select, Store } from "@ngrx/store";
import { RetriveArray } from "./Store/Actions/array.action";
import { AppState } from "../models/app.state";
import { ArrayService } from "./service/array.service";
import { CcxArrayService } from "./service/ccxArray.service";
import { RetriveCcxArray } from "./Store/Actions/ccxArray.action";

@Component({
  // tslint:disable-next-line
  selector: "body",
  // template: "<router-outlet></router-outlet>"
  templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit {
  array$ = this.store.pipe(select(allArray));
  showLoader = true;

  constructor(private router: Router, private store: Store<AppState>, private arrayService: ArrayService, private ccxArrayService: CcxArrayService) {}

  async ngOnInit() {
    try {
      //fetch NML array
      const res = await this.arrayService.getArray();
      if (res.error) return;
      this.store.dispatch(RetriveArray({ array: res }));

      //fetch CCX array
      const ccxRes = await this.ccxArrayService.getArray();
      if (ccxRes.error) return;
      this.store.dispatch(RetriveCcxArray({ ccxArray: ccxRes }));

      //Stop the global Loader
      this.showLoader = false;
    } catch (error) {
      console.log(error);
    }

    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
