import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../../models/app.state";
import { setArrayObject } from "../Store/Actions/arrayObject.action";
import { deleteApi, getApi, postApi } from "../utils/APIconstant";

@Injectable({
  providedIn: "root"
})

export class ArrayService {
  constructor(private store: Store<AppState>) {}

  async getArray() {
    const res = await getApi("readArray");
    console.log("=====getArray=======", res);
    this.store.dispatch(setArrayObject({ arrayObject: res.reduce((a, b) => ({ ...a, [b.collection_name]: b }), {}) }));
    return res;
  }

  async writeArray(data) {
    return postApi("writeArray", data);
  }
  async deleteArray(data) {
    return postApi("deleteArray", data);
  }
}
