import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-message-modal",
  templateUrl: "./message-modal.component.html",
  styleUrls: ["./message-modal.component.scss"]
})
export class MessageModalComponent implements OnInit {
  @Input() modalObject: { message: string; show: boolean };
  @Output() modalObjectChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  closeModal(e) {
    if (!e) this.modalObjectChange.emit({ show: false, message: "" });
    console.log(e.target);
    if (e.target.closest(".ccx-meesage-modal-body")) return;
    this.modalObjectChange.emit({ show: false, message: "" });
  }
}
