import { ActionReducerMap, combineReducers, MetaReducer } from "@ngrx/store";
import { environment } from "../../../environments/environment";
import { ArrayReducer } from "./array.reducer";
import { ArrayObjectReducer } from "./arrayObject.reducer";
import { CcxArrayReducer } from "./ccxArray.reducer";
import { CcxArrayObjectReducer } from "./ccxArrayObject.reducer";
import { GlobalSettingReducer } from "./globalSetting.reducer";

export interface State {}

export const reducers: ActionReducerMap<State> = {
  state: combineReducers({
    array: ArrayReducer,
    arrayObject: ArrayObjectReducer,
    ccxArray: CcxArrayReducer,
    ccxArrayObject: CcxArrayObjectReducer,
    globalSetting: GlobalSettingReducer
  })
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
