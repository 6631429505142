import { createReducer, on } from "@ngrx/store";
import { GlobalSettingInterface } from "../../../models/globalsettingInterface";
import { retriveGlobalSetting } from "../Actions/globalSetting";

const initialState: GlobalSettingInterface[] = [];

const _GlobalSettingReducer = createReducer(
  initialState,
  on(retriveGlobalSetting, (state, { settigns }) => {
    return [...settigns];
  })
);

export function GlobalSettingReducer(state: any, actions: any) {
  return _GlobalSettingReducer(state, actions);
}
