import { createAction, props } from "@ngrx/store";
import { ArrayInterface } from "../../../models/arrayInterface";

export enum AraayActionTypes {
  Add = "[array] Add Array",
  Remove = "[array] Remove Array",
  retrieve = "[array] Load Array"
}

export const AddArray = createAction(AraayActionTypes.Add);
export const RetriveArray = createAction(AraayActionTypes.retrieve, props<{ array: ArrayInterface[] }>());
