import { createReducer, on } from "@ngrx/store";
import { ArrayInterface } from "../../../models/arrayInterface";

import { RetriveArray } from "../Actions/array.action";

const initialState: ArrayInterface[] = [];

const _ArrayReducer = createReducer(
  initialState,
  on(RetriveArray, (state, { array }) => {
    return [...array];
  })
);

export function ArrayReducer(state: any, actions: any) {
  return _ArrayReducer(state, actions);
}
