import { createReducer, on } from "@ngrx/store";
import { ArrayInterface } from "../../../models/arrayInterface";
import { setArrayObject } from "../Actions/arrayObject.action";

const initialState: { [property: string]: ArrayInterface } = {};

const _ArrayObjectReducer = createReducer(
  initialState,
  on(setArrayObject, (state, { arrayObject }) => {
    return { ...arrayObject };
  })
);

export function ArrayObjectReducer(state: any, actions: any) {
  return _ArrayObjectReducer(state, actions);
}
