import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Import Containers
import { DefaultLayoutComponent } from "./containers";

import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { LoginComponent } from "./views/login/login.component";
import { RegisterComponent } from "./views/register/register.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full"
  },
  {
    path: "404",
    component: P404Component,
    data: {
      title: "Page 404"
    }
  },
  {
    path: "500",
    component: P500Component,
    data: {
      title: "Page 500"
    }
  },
  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "Login Page"
    }
  },
  {
    path: "register",
    component: RegisterComponent,
    data: {
      title: "Register Page"
    }
  },
  {
    path: "",
    component: DefaultLayoutComponent,
    data: {
      title: "Home"
    },
    children: [
      {
        path: "dashboard",
        loadChildren: () => import("./views/dashboard/dashboard.module").then(m => m.DashboardModule)
      },
      {
        path: "games",
        loadChildren: () => import("./views/games/games.module").then(m => m.GamesModule)
      },
      {
        path: "arrays",
        loadChildren: () => import("./views/arrays/arrays.module").then(m => m.ArraysModule)
      },
      {
        path: "membership",
        loadChildren: () => import("./views/membership/membership.module").then(m => m.MembershipModule)
      },
      {
        path: "educator",
        loadChildren: () => import("./views/educator/educator.module").then(m => m.EducatorModule)
      },
      {
        path: "progress",
        loadChildren: () => import("./views/progress/progress.module").then(m => m.ProgressModule)
      },
      {
        path: "assets-repo",
        loadChildren: () => import("./views/assets-repo/assets-repo.module").then(m => m.AssetsRepoModule)
      }
    ]
  },
  { path: "**", component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
