import { Component, Input, OnInit } from "@angular/core";
import {
  ageGroupList,
  countriesList,
  distributionChannelsList,
  divisionsList,
  episodesList,
  gradeList,
  languagesList,
  publishersList,
  salesDistrictList,
  salesGroupList,
  salesOfficeList,
  salesOrganizationsList,
  seriesList,
  titlesList
} from "../../../data/comman-data";
const filters = {
  publisher: "",
  sortTitleId: "",
  extTitle: "",
  origLang: "",
  seriesId: "",
  episodeId: "",
  group: "",
  grade: "",
  title: "",
  salesOrganization: "",
  distributionChannel: "",
  division: "",
  salesOffice: "",
  salesGroup: "",
  salesDistrict: "",
  companyCode: "",
  geography: "",
  description: "",
  country: "",
  includedCountries: "",
  excludedCountries: "",
  firstName: "",
  lastName: "",
  companyName: "",
  language: "",
  attributeName: "",
  gender: "",
  attributeValue: "",
  seriesTitle: "",
  count: "",
  skuDesciption: "",
  productType: "",
  store: ""
};

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.css"]
})
export class FilterComponent implements OnInit {
  @Input() filters: any;
  publishers = publishersList;
  titles = titlesList;
  languages = languagesList;
  series = seriesList;
  episodes = episodesList;
  ageGroups = ageGroupList;
  grades = gradeList;
  salesOrganization = salesOrganizationsList;
  distributionChannels = distributionChannelsList;
  divisions = divisionsList;
  salesOffices = salesOfficeList;
  salesGroups = salesGroupList;
  salesDistricts = salesDistrictList;
  countries = countriesList;

  filterState = { ...filters };

  constructor() {}

  ngOnInit(): void {}
  resetFilter() {
    this.filterState = { ...filters };
  }
}
