import { createReducer, on } from "@ngrx/store";
import { ArrayInterface } from "../../../models/arrayInterface";
import { setCcxArrayObject } from "../Actions/ccxArrayObject.action";

const initialState: { [property: string]: ArrayInterface } = {};

const _CcxArrayObjectReducer = createReducer(
  initialState,
  on(setCcxArrayObject, (state, { ccxArrayObject }) => {
    return { ...ccxArrayObject };
  })
);

export function CcxArrayObjectReducer(state: any, actions: any) {
  return _CcxArrayObjectReducer(state, actions);
}
