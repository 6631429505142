import { Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { navItems } from "../../_nav";

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html"
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = navItems();

  constructor(private route: Router) {
    this.route.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.navItems = navItems(event.url);
    });
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
}
