import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-ccx-loader",
  templateUrl: "./ccx-loader.component.html",
  styleUrls: ["./ccx-loader.component.scss"]
})
export class CcxLoaderComponent implements OnInit {
  @Input() showLoader: boolean;

  constructor() {}

  ngOnInit(): void {}
}
