import axios from "axios";

export const baseURI = `https://us-central1-nml-portal-dev.cloudfunctions.net`;
// export const baseURI = `http://localhost:5001/nml-portal-dev/us-central1/`;

axios.defaults.baseURL = baseURI;

export const getCcxApi = async (url: string, params?: any) => {
  try {
    const { data }: { data: any } = await axios.get(`https://us-central1-ccx-vs-frb-dev.cloudfunctions.net/${url}`, params);
    return data;
  } catch (error) {
    return { error: error.message };
  }
};

export const getApi = async (url: string, params?: any) => {
  try {
    const { data }: { data: any } = await axios.get(url, params);
    return data;
  } catch (error) {
    return { error: error.message };
  }
};

export const postApi = async (url: string, req: any, params?: any) => {
  try {
    const { data }: { data: any } = await axios.post(url, req, params);
    return data;
  } catch (error) {
    console.log("error action", error);
    if (error.response?.data?.message) return { error: error.response?.data?.message };
    return { error: error.message };
  }
};

export const patchApi = async (url: string, req: any, params?: any) => {
  try {
    const { data } = await axios.patch(url, req, params);
    return data;
  } catch (error) {
    console.log("error action", error);
    if (error.response?.data?.message) return { error: error.response?.data?.message };
    return { error: error.message };
  }
};
export const putApi = async (url: string, req: any, params?: any) => {
  try {
    const { data } = await axios.put(url, req, params);
    return data;
  } catch (error) {
    console.log("error action", error);
    if (error.response?.data?.message) return { error: error.response?.data?.message };
    return { error: error.message };
  }
};

export const deleteApi = async (url: string, params?: any) => {
  try {
    const { data } = await axios.delete(url, params);
    return data;
  } catch (error) {
    return { error: error.message };
  }
};
