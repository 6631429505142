import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-option-selector-input",
  templateUrl: "./option-selector-input.component.html",
  styleUrls: ["./option-selector-input.component.scss"]
})
export class OptionSelectorInputComponent implements OnInit, OnChanges {
  @Input() selectedValue: any;
  @Input() options: any;
  @Input() option_value: string;
  @Input() option_description: string;
  @Input() simple?: string;
  @Input() disabledSelect?: boolean;
  @Input() onChange?: any;
  @Input() checkLable?: boolean;
  @Output() selectedValueChange = new EventEmitter<any>();

  selected: string = "";

  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.selected = this.selectedValue;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedValue) this.selected = changes.selectedValue.currentValue;
  }

  changeDetect(value) {
    this.selectedValueChange.emit(value);
    if (this.onChange) {
      this.onChange(value, this.options);
      setTimeout(() => {
        this.ref.detectChanges();
      }, 100);
    }
  }
}
